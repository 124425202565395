
import { GenericError } from "@/services/error_service";
import { Vue, Component, Prop } from "vue-property-decorator";
import AuthService from "@/services/auth_service";

@Component
export default class UserAuthentication extends Vue {
  @Prop() props!: any;
  protected authService = new AuthService();
  protected firstName = this.$route.query.firstName as string;
  protected lastName = this.$route.query.lastName as string;
  protected userEmail = this.$route.query.email as string;
  protected role = this.$route.query.type as string;
  protected company = this.$route.query.company as string;
  protected repEmail = '';
  protected displayOverlay = false;

  // computed property
  get objectToEncrypt() {
    const escFirstName = escape(this.firstName);
    const escLastName = escape(this.lastName);
    const escCompany = escape(this.company);
    const escUserEmail = escape(this.userEmail);
    const escRepEmail = escape(this.repEmail);
    return {
      keys: [`${escFirstName},${escLastName},${escCompany},${escUserEmail},${this.role},${escRepEmail}`]
    }
  }

  protected async submitApproval(): Promise<void> {
    try {
      this.displayOverlay = true;
      const key: string = await this.authService.postUserForEncryption(this.objectToEncrypt) as string;
      await this.authService.approveNewUser(
        this.firstName,
        this.lastName,
        this.company,
        this.userEmail,
        this.repEmail,
        key
      );
      this.$router.push(`/auth-success?success=true`);
    } catch (err) {
      this.displayOverlay = false;
      GenericError.popup(err.message);
    }
  }
}
